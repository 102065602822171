import React, { lazy, Suspense, useState, useEffect } from "react";
import UserParams from 'utils/UserParams';
import LoginAdmin from "views/admin/LoginAdmin";
import LoginWeb from "views/web/LoginPage/LoginPage";
import PARAMS from "utils/PARAMS";
import * as API from "utils/API_V2";

import { Router, Route, Switch, Redirect } from "react-router-dom";
import CustomLoading from "./components/CustomLoading/CustomLoading"
import { createBrowserHistory } from "history";
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from 'redux/actions/userActions';


const Admin = lazy(() => import('./layouts/Admin'));
const Web = lazy(() => import('./layouts/Web'));
const hist = createBrowserHistory();
const loading = () => <CustomLoading />;




export default function App(prop) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  let userInfo = useSelector(state => state.user)

  let rsp = <></>;

  if(userInfo.loading){
    rsp = <CustomLoading/>;
  }else{
    if(userInfo.login){
      rsp = <Router history={hist}>
            <Suspense fallback={loading()}>
               {(userInfo.role == 'admin') && <Switch>
                <Route path="/" component={Admin} />
               </Switch>}
               {(userInfo.role == 'organizacion') && <Switch>
                 <Route path="/" component={Web} />
               </Switch>}
               </Suspense>
               </Router>;
    }else{
      switch (true) {
        case window.location.href.includes('administrador'):
          return(<LoginAdmin />)
          break;
        default:
          return(<LoginWeb />)
      }
    }
  }
  return rsp;
  // const isAdmin = async(e) => {
  //   let res;
  //   if (apiToken != '') {
  //     res = await API.isAdminAuth();
  //   }else {
  //     res = await API.isAdminGuest();
  //   }

  //   if (res.error) {
  //     setLogin(false)
  //   }else{
  //     if (res.data == 'admin') {
  //       UserParams.setReadOnly(true);
  //     }else{
  //       UserParams.setReadOnly(false);
  //     }
  //     setUser(res.data)
  //     setLogin(true)
  //   }
  //   setLoad(true)
  // }

  // useEffect(() => {
  //   isAdmin();
  // }, []);

  // if (load) {
  //   if(login){
  //     return (
  //         <Router history={hist}>
  //         {(user == 'admin') && <Switch>
  //           <Route path="/" component={Admin} />
  //         </Switch>}
  //         {(user == 'organizacion') && <Switch>
  //           <Route path="/" component={Web} />
  //         </Switch>}
  //         </Router>
  //     );
  //   }else{
  //     switch (true) {
  //       case window.location.href.includes(PARAMS.panel_url):
  //         return(<LoginAdmin />)
  //         break;
  //       default:
  //         return(<LoginWeb />)
  //     }
  //   }

  // }else{
    return(
      <CustomLoading/>
    )
  // }

}
