export const VARIABLE_GUARDAR = 'VARIABLE_GUARDAR';
export const VARIABLE_GUARDAR_EXITO = 'VARIABLE_GUARDAR_EXITO';
export const VARIABLE_GUARDAR_ERROR = 'VARIABLE_GUARDAR_ERROR';
export const VARIABLE_CARGAR_PROCESO = 'VARIABLE_CARGAR_PROCESO';
export const VARIABLE_CARGAR_EXITO = 'VARIABLE_CARGAR_EXITO';
export const VARIABLE_CARGAR_ERROR = 'VARIABLE_CARGAR_ERROR';
export const ELIMINAR_VARIABLES_PROCESO = 'ELIMINAR_VARIABLES_PROCESO';
export const ELIMINAR_VARIABLES_EXITO = 'ELIMINAR_VARIABLES_EXITO';
export const ELIMINAR_VARIABLES_ERROR = 'ELIMINAR_VARIABLES_ERROR';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADING_SUCCESS = 'USER_LOADING_SUCCESS';
export const USER_LOADING_ERROR = 'USER_LOADING_ERROR';
export const CONVOCATORIA_CARGAR_PROCESO = 'CONVOCATORIA_CARGAR_PROCESO';
export const CONVOCATORIA_CARGAR_EXITO = 'CONVOCATORIA_CARGAR_EXITO';
export const CONVOCATORIA_CARGAR_ERROR = 'CONVOCATORIA_CARGAR_ERROR';
export const CONVOCATORIA_INICIADA_CARGAR_PROCESO = 'CONVOCATORIA_INICIADA_CARGAR_PROCESO'; 
export const CONVOCATORIA_INICIADA_CARGAR_EXITO = 'CONVOCATORIA_INICIADA_CARGAR_EXITO'; 
export const CONVOCATORIA_INICIADA_CARGAR_ERROR = 'CONVOCATORIA_INICIADA_CARGAR_ERROR'; 