import loginAdminImg from "assets/img/sidebar-3.jpg";
import iconImg from "assets/img/logo_medioambiente.jpg";
import sideBarImg from "assets/img/sidebar-3.jpg";
import logoFront from "assets/img/cabecera_panel.jpg";

var params = [];
// build PROD
params['baseUrl'] = 'https://planes-ambientalesymovilidad.cordoba.es/';
params['apiUrl'] = 'https://apiplanes-ambientalesymovilidad.cordoba.es/api/';
// run LOCAL
// params['baseUrl'] = 'http://localhost:3000/';
// params['apiUrl'] = 'https://api_ayun_medioambiente.local/api/';
params['loginAdminImg'] = loginAdminImg;
params['sideBarIcon'] = iconImg;
params['sideBarImg'] = sideBarImg;
params['logoFront'] = logoFront;
params['sideBarColor'] = '#B00202';
params['firstColorRGB'] = '176, 2, 2';
params['firstColor'] = '#B00202';
params['firstColorHover'] = '#B00202';
params['secondColor'] = '#D9D5F5';
params['color_green'] = '#00C534';
params['color_red'] = '#FF0C56';
params['color_grey'] = '#BBBBBB';
params['proyectName'] = 'HERRAMIENTA TIC DE MONITORIZACIÓN PARA SEGUIMIENTO DE LOS PLANES DE MEDIO AMBIENTE Y MOVILIDAD';
params['defaultTableLength'] = 20;
params['tableLengthArray'] = [10, 25, 50];
params['personalizacion'] = false;
params['dev_mode'] = true;
params['panel_url'] = 'administrador';
params['web_url'] = 'entidad';

export default params;
