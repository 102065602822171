import {
    USER_LOADING,
    USER_LOADING_SUCCESS,
    USER_LOADING_ERROR
} from "../types";

const initialState = {
    login: false,
    role: null,
    loading: false
}

export default function (state = initialState, action: { type: any; }) {
    switch (action.type) {
        case USER_LOADING:
            return {
                ...state,
                loading: true,
            }
        case USER_LOADING_SUCCESS:
            return {
                ...state,
                loading: false,
                login: true,
                role: action.role

            }
        case USER_LOADING_ERROR:
            return {
                ...state,
                loading: false,
                login: false
            }
        return state;
        break;
        default:
            return state;
            break;
    }
}
