import Moment from 'moment';

var DNI_REGEX = /^(\d{8})([A-Z])$/;
var CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

export function Validation(variable, value) {
	let $rsp = false;
	switch (variable) {
		case "var4":
			if (!validadorNIFNIE(value) && value != "" && !validadorCIF(value)) {
				$rsp = "NIF no válido";
			}
			break;
		default:
			break;
	}
	return $rsp;
}

function validadorCIF(value) {
	if (!value || value.length !== 9) {
		return false;
	}

	var letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
	var digits = value.substr(1, value.length - 2);
	var letter = value.substr(0, 1);
	var control = value.substr(value.length - 1);
	var sum = 0;
	var i;
	var digit;

	if (!letter.match(/[A-Z]/)) {
		return false;
	}

	for (i = 0; i < digits.length; ++i) {
		digit = parseInt(digits[i]);
		if (isNaN(digit)) {
			return false;
		}

		if (i % 2 === 0) {
			digit *= 2;
			if (digit > 9) {
				digit = parseInt(digit / 10) + (digit % 10);
			}

			sum += digit;
		} else {
			sum += digit;
		}
	}

	sum %= 10;
	if (sum !== 0) {
		digit = 10 - sum;
	} else {
		digit = sum;
	}

	if (letter.match(/[ABEH]/)) {
		return String(digit) === control;
	}
	if (letter.match(/[NPQRSW]/)) {
		return letters[digit] === control;
	}

	return String(digit) === control || letters[digit] === control;
}

/*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
function isValidIBANNumber(input) {
	if (!/^([0-9a-zA-Z]\s?){24}$/.test(input)) {
		return false;
	}
	var CODE_LENGTHS = {
		AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
		CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
		FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
		HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
		LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
		MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
		RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
		AL: 28, BY: 28, CR: 22, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
		SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
	};
	var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
		code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
		digits;
	// check syntax and length
	if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
		return false;
	}
	// rearrange country code and check digits, and convert chars to ints
	digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
		return letter.charCodeAt(0) - 55;
	});
	// final check
	if (mod97(digits) == 1) {
		return true;
	}
	return false;
}

function mod97(string) {
	var checksum = string.slice(0, 2), fragment;
	for (var offset = 2; offset < string.length; offset += 7) {
		fragment = String(checksum) + string.substring(offset, offset + 7);
		checksum = parseInt(fragment, 10) % 97;
	}
	return checksum;
}

function validadorNIFNIE(value) {
	var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
	var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
	var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
	var str = value.toString().toUpperCase();

	if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

	var nie = str
		.replace(/^[X]/, '0')
		.replace(/^[Y]/, '1')
		.replace(/^[Z]/, '2');

	var letter = str.substr(-1);
	var charIndex = parseInt(nie.substr(0, 8)) % 23;

	if (validChars.charAt(charIndex) === letter) return true;

	return false;
}

/* Nuevas validaciones */


function CustomException(message, field) {
	this.message = message;
	this.field = field;
}

// Validación de un dato específico
export function check(type, value, field) {
	var error = [];
	error['status'] = true;
	var field = '';

	try {
		validate(type, value, field)
		return error;
	} catch (e) {
		error['message'] = e.message;
		error['field'] = e.field;
		error['status'] = false;
		return error;
	}
}

// Validación de las keys de un objeto, la variable `validacion` es una replica del objeto solo con las keys a validar y valor igual al tipo de validación
export function checkObject(validacion, data) {
	console.log(validacion, data)
	var error = [];
	error['status'] = true;
	var field = '';

	try {
		for (var key of Object.keys(validacion)) {
			validate(validacion[key].type, data[key], validacion[key].field)
		}
		return error;
	} catch (e) {
		error['message'] = e.message;
		error['field'] = e.field;
		error['status'] = false;
		console.log(error)
		return error;
	}
}

export function validate(type, value, field) {
	var res = null;

	switch (type) {
		case "NULL":
			validadorNull(value, field)
			break;
		case "ZERO":
			validadorZero(value, field)
			break;
		case "SELECTOR":
			validadorSelector(value, field)
			break;
		case "DATE":
			validadorDate(value, field)
			break;
		case "CIF":
			res = validCIF(value);
			if (!res) {
				throw new CustomException(`El campo ${field} no es un CIF válido`, field);
			}
			break;
		case "NIF":
			res = validDNI(value);
			if (!res) {
				res = validNIE(value)
				if (!res) {
					throw new CustomException(`El campo ${field} no es un NIF válido`, field);
				}
			}
			break;
		case "EMAIL":
			validadorEmail(value, field)
			break;
		case "PHONE":
			validadorPhone(value, field)
			break;
		case "INTEGER":
			validadorInteger(value, field)
			break;
		case "FLOAT":
			validadorFloat(value, field)
			break;
		default:

	}
}

function validadorNull(value, key) {
	if (value === '' || value === null || value === []) {
		throw new CustomException(`El campo ${key} no puede estar vacio`, key);
	}
}

function validadorZero(value, key) {
	if (value === '' || value === null || value === 0 || value === "0") {
		throw new CustomException(`El campo ${key} no puede ser 0`, key);
	}
}

function validadorSelector(value, key) {
	if (value === '' || value === null || value === []) {
		throw new CustomException(`El selector ${key} no puede estar vacio`, key);
	}
}

function validadorInteger(value, key) {
	if (value !== parseInt(value, 10)) {
		throw new CustomException(`El campo ${key} debe ser un número`, key);
	}
}

function validadorFloat(value, key) {
	if (!/^-?\d*(\.\d+)?$/.test(value)) {
		throw new CustomException(`El campo ${key} debe ser un número`, key);
	}
}

function validadorDate(value, key) {
	if (value === '' || value === null) {
		throw new CustomException(`El campo ${key} no puede estar vacio`, key);
	}
	var date = Moment(value);
	if (date.isValid()) {
		return true;
	}
	throw new CustomException(`El campo ${key} no es una fecha válida`, key);
}

function validadorEmail(value, key) {
	if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
		throw new CustomException(`El campo ${key} no es un email válido`, key);
	}
}

function validadorPhone(value, key) {
	if (!/([0-9]){9}/.test(value)) {
		throw new CustomException(`El campo ${key} no es un teléfono válido`, key);
	}
}

var validDNI = function (dni) {
	var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
	var letter = dni_letters.charAt(parseInt(dni, 10) % 23);

	return letter == dni.charAt(8);
}

var validNIE = function (nie) {

	// Change the initial letter for the corresponding number and validate as DNI
	var nie_prefix = nie.charAt(0);

	switch (nie_prefix) {
		case 'X': nie_prefix = 0; break;
		case 'Y': nie_prefix = 1; break;
		case 'Z': nie_prefix = 2; break;
	}

	return validDNI(nie_prefix + nie.substr(1));

}

var validCIF = function (cif) {
	var match = cif.match(CIF_REGEX);
	if (match) {

		var letter = match[1],
			number = match[2],
			control = match[3];

		var even_sum = 0;
		var odd_sum = 0;
		var n;

		for (var i = 0; i < number.length; i++) {
			n = parseInt(number[i], 10);

			// Odd positions (Even index equals to odd position. i=0 equals first position)
			if (i % 2 === 0) {
				// Odd positions are multiplied first.
				n *= 2;

				// If the multiplication is bigger than 10 we need to adjust
				odd_sum += n < 10 ? n : n - 9;

				// Even positions
				// Just sum them
			} else {
				even_sum += n;
			}

		}

		var control_digit = (10 - (even_sum + odd_sum).toString().substr(-1));
		var control_letter = 'JABCDEFGHI'.substr(control_digit, 1);

		// Control must be a digit
		if (letter.match(/[ABEH]/)) {
			return control == control_digit;

			// Control must be a letter
		} else if (letter.match(/[KPQS]/)) {
			return control == control_letter;

			// Can be either
		} else {
			return control == control_digit || control == control_letter;
		}
	} else {
		return false;
	}

}

