// utils/API.js

import axios from "axios";
import PARAMS from "utils/PARAMS";
import { toast } from 'react-toastify';

// Instancia para api
var instance = axios.create({
  baseURL: PARAMS.apiUrl,
  timeout: 10000
});

instance.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("apiToken");
instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        if (localStorage.getItem("apiToken")) {
          toast("Sesión caducada", {type: "error"});
          axios.get('logout');
          localStorage.removeItem('apiToken');
          localStorage.removeItem('userType');
          window.location.replace(PARAMS.baseUrl);
        }else{
          // toast("Usuario no autorizado", {type: "error"});
        }
        break;
      case 404:
        toast("Url no disponible", {type: "error"});
        break;
      case 500:
        break;
      default:

    }
  }else{
    toast("No se ha podido establecer conexión", {type: "error"});
  }

  return Promise.reject(error);
});

// Instancia para api responseType blob
var instance_blob = axios.create({
  baseURL: PARAMS.apiUrl,
  // crossdomain: true
});
instance_blob.defaults.responseType = 'blob';
// instance_blob.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
// instance_blob.defaults.headers.post['Accept'] ='application/pdf';
// instance_blob.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
instance_blob.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("apiToken");
instance_blob.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    if (localStorage.getItem("apiToken")) {
      toast("Sesión caducada", {type: "error"});
      instance_blob.get('logout');
      localStorage.removeItem('apiToken');
      localStorage.removeItem('userType');
      window.location.replace(PARAMS.baseUrl);
    }else{
      // toast("Usuario no autorizado", {type: "error"});
    }
  } else {
    return Promise.reject(error);
  }
});

// Instancia para api formdata
var instance_archivo = axios.create({
  baseURL: PARAMS.apiUrl,
});
instance_archivo.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("apiToken");
instance_archivo.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    if (localStorage.getItem("apiToken")) {
      toast("Sesión caducada", {type: "error"});
      instance_archivo.get('logout');
      localStorage.removeItem('apiToken');
      localStorage.removeItem('userType');
      window.location.replace(PARAMS.baseUrl);
    }else{
      // toast("Usuario no autorizado", {type: "error"});
    }
  } else {
    return Promise.reject(error);
  }
});

// Funcion auxiliar async
async function resolve(promise) {
  const resolved = {
    data: null,
    error: null
  };

  try {
    resolved.data = await promise;
  } catch(e) {
    if (e.response && e.response.data && e.response.data.error) {
      resolved.error = e.response.data.error;
    }else{
      resolved.error = 'Se ha producido un error';
    }
  }

  return resolved;
}

// Ruta para verificar tipo de usuario (token)
export async function isAdminAuth() {
  return await resolve(
    instance.post('isAdminAuth').then(res => res.data)
  )
}

// Ruta para verificar tipo de usuario (no token)
export async function isLogin() {
  return await resolve(
    instance.post('isLogin').then(res => res.data)
  )
}

export async function login(email, password, userType) {
  return await resolve(
    instance.post('login', { email, password, userType }).then(res => res.data)
  )
}

export async function register(nombre, email, password, userType) {
  return await resolve(
    instance.post('register', { nombre, email, password, userType }).then(res => res.data)
  )
}

export async function resetPass(email) {
  return await resolve(
    instance.post('resetPassword/create', { email }).then(res => res.data)
  )
}

export async function logout() {
  return await resolve(
    instance.get('logout').then(res => res.data)
  )
}

export async function getUser() {
  return await resolve(
    instance.post('usuarios/show').then(res => res.data)
  )
}

export async function getPermiso() {
  return await resolve(
    instance.post('permiso/index').then(res => res.data)
  )
}

export async function userIndex(search, page, perPageData) {
  return await resolve(
    instance.post('usuarios/index', { search, page, perPageData }).then(res => res.data)
  )
}

export async function userStore(usuario) {
  return await resolve(
    instance.post('usuarios/store', usuario).then(res => res.data)
  )
}

export async function userUpdate(usuario) {
  return await resolve(
    instance.post(`usuarios/update/${id}`, usuario).then(res => res.data)
  )
}

export async function userDestroy(id) {
  return await resolve(
    instance.post(`usuarios/destroy/${id}`).then(res => res.data)
  )
}

export async function getCountOrganizacionesStats() {
  return await resolve(
    instance.post(`usuarios/stats`).then(res => res.data)
  )
}

export async function logIndex() {
  return await resolve(
    instance.post('log/index').then(res => res.data)
  )
}

export async function codeIndex(findBy) {
  return await resolve(
    instance.post('codigos/index', {findBy}).then(res => res.data)
  )
}

export async function codeStore(codigo, descripcion) {
  return await resolve(
    instance.post('codigos/store', {codigo, descripcion}).then(res => res.data)
  )
}

export async function codeUpdate(id, codigo, descripcion) {
  return await resolve(
    instance.post(`codigos/update/${id}`, {codigo, descripcion}).then(res => res.data)
  )
}

export async function codeDestroy(id) {
  return await resolve(
    instance.post(`codigos/destroy/${id}`).then(res => res.data)
  )
}

export async function variableShow(variable) {
  return await resolve(
    instance.post(`preguntas/show`, { variable }).then(res => res.data)
  )
}

export async function getRespuestas(findBy) {
  return await resolve(
    instance.post(`respuesta/index`, { findBy }).then(res => res.data)
  )
}

export async function respuestasExcel(convocatoria) {
  return await resolve(
    instance.post(`respuesta/excel`, {convocatoria_id : convocatoria}).then(res => res.data)
  )
}

export async function existeSubsanacion(id) {
  return await resolve(
    instance.post(`respuesta/existe_subsanacion`, {id: id}).then(res => res.data)
  )
}

export async function cargarVariables(variables, user_id, subsanacion = 'inicial', convocatoria= false) {
  return await resolve(
    instance.post(`respuesta/cargar`, { variables, user_id, subsanacion,convocatoria }).then(res => res.data)
  )
}

export async function guardarVariable(variable, value, orden = null, user_id = null, tipo = 'inicial', convocatoria) {
  return await resolve(
    instance.post("respuesta/guardar", {variable, value, orden, user_id, tipo,convocatoria}).then(res => res.data)
  )
}

export async function eliminarVariables(variable, orden, user_id = null) {
  return await resolve(
    instance.post("respuesta/eliminar", {variable, orden, user_id}).then(res => res.data)
  )
}

export async function getCountRespuestasStats() {
  return await resolve(
    instance.post(`respuesta/stats`).then(res => res.data)
  )
}

export async function getCountPlanesCompletos() {
  return await resolve(
    instance.post(`respuesta/planes_stats`).then(res => res.data)
  )
}

export async function guardarArchivo(file, variable,convocatoria) {
  let data = new FormData();
  data.append('file', file);
  data.append('variable', variable);
  data.append('convocatoria', convocatoria);
  let settings = { headers: { 'content-type': 'multipart/form-data' } }

  return await resolve(
    instance_archivo.post("guardarArchivo", data, settings).then(res => res.data)
  )
}

export async function obtenerArchivo(variable, user_id,convocatoria) {
  let data = new FormData();
  data.append('variable', variable);
  data.append('user_id', user_id);
  data.append('convocatoria', convocatoria);
  return await resolve(
    instance_archivo.post("obtenerArchivo", data).then(res => res.data)
  )
}

export async function borrarArchivo(variable, file_name,convocatoria) {
  let data = new FormData();
  data.append('nombre_archivo', file_name);
  data.append('variable', variable);
  data.append('convocatoria', convocatoria);
  return await resolve(
    instance_archivo.post("borrarArchivo", data).then(res => res.data)
  )
}

export async function importCodigos(input) {
  let data = new FormData();
  data.append('file', input);
  let settings = { headers: { 'content-type': 'multipart/form-data' } };

  return await resolve(
    instance_archivo.post("codigosUsuario/import", data).then(res => res.data)
  )
}

export async function obtenerNombrePDF(convocatoria_id, ambito_id, plan_id, exportacion_completa) {
  return await resolve(
    instance.post("pdf/obtenernombre", { convocatoria_id, ambito_id, plan_id, exportacion_completa }).then(res => res.data)
  )
}

export async function descargarPDFConvocatoria(convocatoria_id, ambito_id, plan_id, exportacion_completa, textoExtraInforme) {
  return await resolve(
    instance_blob.post(`pdf/convocatoria`, { convocatoria_id, ambito_id, plan_id, exportacion_completa, textoExtraInforme }).then(res => res.data)
  )
}

export async function getPDF(nombre, tipo) {

  let data = new FormData();
  data.append('nombrearchivo', nombre);

  return await resolve(
    instance_blob.post(`pdf/${tipo}`, data).then(res => res.data)
  )
}
export async function getPDFAutoliquidacion(convocatoria= false) {
  return await resolve(
    instance_blob.post(`pdf/get_autoliquidacion`, { convocatoria }).then(res => res.data)
  )
}
export async function getConvocatorias(fecha){
  let data = new FormData();
  data.append('fecha', fecha);
  return await resolve(
    instance.post("respuesta/listado_convocatorias_disponibles", data).then(res => res.data)
  )
}
  export async function getConvocatoriasIniciadas(fecha){
    let data = new FormData();
    return await resolve(
      instance.post("respuesta/listado_convocatorias_iniciadas", data).then(res => res.data)
    )
}


/** --------------------------------------------------------------------------------------
* ----------------------------------- Organización  --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function organizacionesAll() {
  return await resolve(
    instance.post('organizacion/all').then(res => res.data)
  )
}

export async function organizacionActivo(id, value) {
  return await resolve(
    instance.post(`organizacion/activo/${id}`, {value}).then(res => res.data)
  )
}

export async function organizacionAutorizar(id) {
  return await resolve(
    instance.post(`organizacion/autorizar/${id}`).then(res => res.data)
  )
}

export async function organizacionDestacado(id, value) {
  return await resolve(
    instance.post(`organizacion/destacado/${id}`, {value}).then(res => res.data)
  )
}

export async function organizacionesIndex(search, page, perPageData) {
  return await resolve(
    instance.post('organizacion/index', { search, page, perPageData }).then(res => res.data)
  )
}

export async function findEmpresaByUrl(url) {
  return await resolve(
    instance.post('organizacion/findByUrl', { url }).then(res => res.data)
  )
}

export async function organizacionesListOnlyAuthorized() {
  return await resolve(
    instance.post('organizacion/list_autorizada').then(res => res.data)
  )
}

export async function organizacionesList() {
  return await resolve(
    instance.post('organizacion/list').then(res => res.data)
  )
}

export async function organizacionServiciosList() {
  return await resolve(
    instance.post('organizacion/servicios_list').then(res => res.data)
  )
}

export async function organizacionStore(empresa) {

  let fd = new FormData();

  for (var key in empresa) {
    if (empresa[key] && !Array.isArray(empresa[key])) {
      fd.append(key, empresa[key]);
    }else{
      fd.append(key, JSON.stringify(empresa[key]));
    }
  }

  return await resolve(
    instance.post('organizacion/store', fd).then(res => res.data)
  )

}

export async function organizacionUpdate(empresa) {

  let fd = new FormData();

  for (var key in empresa) {
    if (empresa[key] && !Array.isArray(empresa[key])) {
      fd.append(key, empresa[key]);
    }else{
      fd.append(key, JSON.stringify(empresa[key]));
    }
  }

  return await resolve(
    instance.post(`organizacion/update/${empresa.id}`, fd).then(res => res.data)
  )

}

export async function organizacionDestroy(id) {
  return await resolve(
    instance.post(`organizacion/destroy/${id}`).then(res => res.data)
  )
}

export async function organizacionCheckPayment() {
  return await resolve(
    instance.post(`organizacion/check_payment`).then(res => res.data)
  )
}

export async function organizacionSubscriptionPayment(paquete_id, payment_method_id) {
  return await resolve(
    instance.post(`organizacion/subscription_payment`, {paquete_id, payment_method_id}).then(res => res.data)
  )
}


/** --------------------------------------------------------------------------------------
* ----------------------------------- Convocatorias --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function convocatoriasIndex(findBy, page, perPageData) {
  return await resolve(
    instance.post('convocatorias/index', { findBy, page, perPageData }).then(res => res.data)
  )
}
// export async function solicitudesIndex(findBy, page, perPageData) {
//   return await resolve(
//     instance.post('solicitudes/index', { findBy, page, perPageData }).then(res => res.data)
//   )
// }


export async function convocatoriaStore(convocatoria) {
  let fd = new FormData();

  for (var key in convocatoria) {
    if (convocatoria[key]) {
      fd.append(key, convocatoria[key]);
    }
  }

  return await resolve(
    instance.post('convocatorias/store', fd).then(res => res.data)
  )
}

export async function convocatoriasTextoPortada() {
  return await resolve(
    instance.post('convocatorias/texto').then(res => res.data)
  )
}

export async function convocatoriasTextoPortadaStore(textoPortada) {

  let fd = new FormData();
  fd.append("texto_portada", textoPortada);

  return await resolve(
    instance.post('convocatorias/texto_store', fd).then(res => res.data)
  )
}


export async function convocatoriaUpdate(convocatoria) {
  let fd = new FormData();

  for (var key in convocatoria) {
    if (convocatoria[key]) {
      fd.append(key, convocatoria[key]);
    }
  }

  return await resolve(
    instance.post(`convocatorias/update/${convocatoria.id}`, fd).then(res => res.data)
  )
}

export async function convocatoriaDestroy(id) {
  return await resolve(
    instance.post(`convocatorias/destroy/${id}`).then(res => res.data)
  )
}

export async function convocatoriasList() {
  return await resolve(
    instance.post(`convocatorias/list`).then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Ambitos --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function ambitoList(search) {
  return await resolve(
    instance.post('ambitos/list', { search }).then(res => res.data)
  )
}

export async function ambitoIndex(findBy, page, perPageData) {
  return await resolve(
    instance.post('ambitos/index', { findBy, page, perPageData }).then(res => res.data)
  )
}

export async function ambitoStore(convocatoria) {
  let fd = new FormData();

  for (var key in convocatoria) {
    if (convocatoria[key]) {
      fd.append(key, convocatoria[key]);
    }
  }

  return await resolve(
    instance.post('ambitos/store', fd).then(res => res.data)
  )
}

export async function ambitoUpdate(ambito) {
  let fd = new FormData();

  for (var key in ambito) {
    if (ambito[key]) {
      fd.append(key, ambito[key]);
    }
  }

  return await resolve(
    instance.post(`ambitos/update/${ambito.id}`, fd).then(res => res.data)
  )
}

export async function ambitoDestroy(id) {
  return await resolve(
    instance.post(`ambitos/destroy/${id}`).then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Planes --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function planList(search) {
  return await resolve(
    instance.post('planes/list', { search }).then(res => res.data)
  )
}

export async function planIndex(findBy, page, perPageData) {
  return await resolve(
    instance.post('planes/index', { findBy, page, perPageData }).then(res => res.data)
  )
}

export async function planStore(plan) {
  let fd = new FormData();

  for (var key in plan) {
    if (plan[key]) {
      fd.append(key, plan[key]);
    }
  }

  return await resolve(
    instance.post('planes/store', fd).then(res => res.data)
  )
}

export async function planUpdate(plan) {
  let fd = new FormData();

  for (var key in plan) {
    if (plan[key]) {
      fd.append(key, plan[key]);
    }
  }

  return await resolve(
    instance.post(`planes/update/${plan.id}`, fd).then(res => res.data)
  )
}

export async function planDestroy(id) {
  return await resolve(
    instance.post(`planes/destroy/${id}`).then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------------- Lineas  --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function lineaIndex(findBy, page, perPageData) {
  return await resolve(
    instance.post('lineas/index', { findBy, page, perPageData }).then(res => res.data)
  )
}

export async function lineaList(search) {
  return await resolve(
    instance.post('lineas/list', { search }).then(res => res.data)
  )
}

export async function lineaStore(linea) {
  let fd = new FormData();

  for (var key in linea) {
    if (linea[key] && !Array.isArray(linea[key])) {
      fd.append(key, linea[key]);
    }else{
      fd.append(key, JSON.stringify(linea[key]));
    }
  }

  return await resolve(
    instance.post('lineas/store', fd).then(res => res.data)
  )
}

export async function lineaUpdate(linea) {
  let fd = new FormData();

  for (var key in linea) {
    if (linea[key] && !Array.isArray(linea[key])) {
      fd.append(key, linea[key]);
    }else{
      fd.append(key, JSON.stringify(linea[key]));
    }
  }

  return await resolve(
    instance.post(`lineas/update/${linea.id}`, fd).then(res => res.data)
  )
}

export async function lineaDestroy(id) {
  return await resolve(
    instance.post(`lineas/destroy/${id}`).then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------------- Preguntas  --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function preguntaIndex(findBy, page, perPageData) {
  return await resolve(
    instance.post('preguntas/index', { findBy, page, perPageData }).then(res => res.data)
  )
}

export async function preguntaList(search) {
  return await resolve(
    instance.post('preguntas/list', { search }).then(res => res.data)
  )
}

export async function preguntaStore(convocatoria) {
  let fd = new FormData();

  for (var key in convocatoria) {
    if (convocatoria[key]) {
      fd.append(key, convocatoria[key]);
    }
  }

  return await resolve(
    instance.post('preguntas/store', fd).then(res => res.data)
  )
}

export async function preguntaUpdate(pregunta) {
  let fd = new FormData();

  for (var key in pregunta) {
    if (pregunta[key]) {
      fd.append(key, pregunta[key]);
    }
  }

  return await resolve(
    instance.post(`preguntas/update/${pregunta.id}`, fd).then(res => res.data)
  )
}

export async function preguntaDestroy(id) {
  return await resolve(
    instance.post(`preguntas/destroy/${id}`).then(res => res.data)
  )
}


export async function tipoPreguntaList(search) {
  return await resolve(
    instance.post('tipo_pregunta/list', { search }).then(res => res.data)
  )
}

export async function getCountIndicadoresStats() {
  return await resolve(
    instance.post(`preguntas/stats`).then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Permisos --------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function permisosList(search) {
  return await resolve(
    instance.post('permiso/list', { search }).then(res => res.data)
  )
}

export async function permisoStore(nombre, permiso_modelos) {
  return await resolve(
    instance.post('permiso/store', { nombre, permiso_modelos }).then(res => res.data)
  )
}

export async function permisoUpdate(id, nombre, permiso_modelos) {
  return await resolve(
    instance.post(`permiso/update/${id}`, { nombre, permiso_modelos }).then(res => res.data)
  )
}

export async function permisoDestroy(id) {
  return await resolve(
    instance.post(`permiso/destroy/${id}`).then(res => res.data)
  )
}
