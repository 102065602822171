import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";


import Login from "components/Login";
import Register from "components/Register";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bg3.jpg";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [login, setLogin] = React.useState(true);
  const [apiToken, setApiToken] = React.useState(null);

  useEffect(() => {
    setApiToken(localStorage.getItem("apiToken"));
  }, []);

  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      {/* <Header
        absolute
        color="transparent"
        brand="IMDEEC"
        rightLinks={<HeaderLinks isLogin={true} />}
        {...rest}
      /> */}
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            {login ?
              <Login login={(data) => {
                setApiToken(data.token);
              }} goRegister={() => setLogin(false)}/>
            :
              <Register login={(data) => {
                setApiToken(data.token);
              }} goLogin={() => setLogin(true)}/>
            }
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
