import {
    VARIABLE_GUARDAR,
    VARIABLE_GUARDAR_EXITO,
    VARIABLE_GUARDAR_ERROR,
    VARIABLE_CARGAR_PROCESO,
    VARIABLE_CARGAR_EXITO,
    VARIABLE_CARGAR_ERROR,
    ELIMINAR_VARIABLES_PROCESO,
    ELIMINAR_VARIABLES_EXITO,
    ELIMINAR_VARIABLES_ERROR,
    CONVOCATORIA_CARGAR_ERROR,
    CONVOCATORIA_CARGAR_EXITO,
    CONVOCATORIA_CARGAR_PROCESO,
    CONVOCATORIA_INICIADA_CARGAR_PROCESO,
    CONVOCATORIA_INICIADA_CARGAR_EXITO,
    CONVOCATORIA_INICIADA_CARGAR_ERROR
} from "../types";
import { find_in_simple_array } from "utils/helperFunctions";
import { toast } from 'react-toastify';
const initialState = {
    variables: {},
    error: null,
    loading: false,
    loading_variables: false,
    variables_error: [],
    variables_success: [],
    mensaje_error: null,
    linea_tipologia: 0,
    loading_convocatoria: false,
    error_convocatoria: false,
    convocatorias: {},
    loading_convocatoria_iniciada: false,
    error_convocatoria_iniciada: false,
    convocatorias_iniciadas: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case VARIABLE_CARGAR_PROCESO:
            return{
                ...state,
                loading: true,
                loading_variables:false,
            }
            break;
        case VARIABLE_GUARDAR:
            return {
                ...state,
                loading: true,
            }
            break;
        case VARIABLE_GUARDAR_EXITO:
            //Agregar al Array de variables guardadas correctamente
            let array_variable_success = state.variables_success;
            if(!find_in_simple_array(array_variable_success,action.variable_success)){
                array_variable_success = [...array_variable_success, action.variable_success];
            }
            //Quitar del Array de variables no guardadas correctamente
            let array_variable_error = state.variables_error;
            if(find_in_simple_array(array_variable_error,action.variable_success)){
                 let index_error = array_variable_error.indexOf(action.variable_success);
                 array_variable_error.splice(index_error, 1);
            }
            if(action.variable_success =='var1'){
              return {
                  ...state,
                  loading: false,
                  variables_success: array_variable_success,
                  variables_error: array_variable_error,
                  linea_tipologia: action.valor
              }

            }else{
              return {
                  ...state,
                  loading: false,
                  variables_success: array_variable_success,
                  variables_error: array_variable_error
              }
            }

            break;
        case VARIABLE_GUARDAR_ERROR:
            //Agregar al Array de variables no guardadas correctamente
            let array_variable_error_2 = state.variables_error;
            if(!find_in_simple_array(array_variable_error_2,action.variable_error)){
                array_variable_error_2.push(action.variable_error);
            }
            //Quitar del Array de variables guardadas correctamente
            let array_variable_success_2 = state.variables_success;
            if(find_in_simple_array(array_variable_success_2,action.variable_error)){
                let index_error = array_variable_success_2.indexOf(action.variable_error);
                array_variable_success_2.splice(index_error, 1);
            }

            toast(action.mensaje_error, {type: "warning"});
            return {
                ...state,
                loading: false,
                error: true,
                variables_success: array_variable_success_2,
                variables_error: array_variable_error_2,
                mensaje_error: action.mensaje_error
            }
            break;
        case VARIABLE_CARGAR_ERROR:
            return {
                ...state,
                loading: false,
                loading_variables:false,
                error: true,
            }
            break;
        case VARIABLE_CARGAR_EXITO:
            return {
                ...state,
                loading: false,
                variables: action.payload,
                loading_variables:false,
                linea_tipologia: action.payload.var1
            }
            break;
        case ELIMINAR_VARIABLES_PROCESO:
            return {
                ...state,
                loading: true
            }
            break;
        case ELIMINAR_VARIABLES_EXITO:
            return {
                ...state,
                loading: false
            }
            break;
        case ELIMINAR_VARIABLES_ERROR:
            return {
                ...state,
                loading: false
            }
            break;
            case CONVOCATORIA_CARGAR_ERROR:
                toast("Ha ocurrido un error al cargar las convocatorias, porfavor intentelo de nuevo", {type: "warning"});
                return {
                    ...state,
                    loading_convocatoria: false,
                    error_convocatoria: true,
                }
                break;
            case CONVOCATORIA_CARGAR_EXITO:
                return {
                    ...state,
                    loading_convocatoria: false,
                    convocatorias: action.payload
                }
                break;
            case CONVOCATORIA_CARGAR_PROCESO:
                return {
                    ...state,
                    loading_convocatoria: true
                }
                break;
                case CONVOCATORIA_INICIADA_CARGAR_ERROR:
                    toast("Ha ocurrido un error al cargar las convocatorias, porfavor intentelo de nuevo", {type: "warning"});
                    return {
                        ...state,
                        loading_convocatoria_iniciada: false,
                        error_convocatoria_iniciada: true,
                    }
                    break;
                case CONVOCATORIA_INICIADA_CARGAR_EXITO:
                    return {
                        ...state,
                        loading_convocatoria_iniciada: false,
                        convocatorias_iniciadas: action.payload
                    }
                    break;
                case CONVOCATORIA_INICIADA_CARGAR_PROCESO:
                    return {
                        ...state,
                        loading_convocatoria_iniciada: true
                    }
                    break;
        default:
            return state;
            break;
    }
}
